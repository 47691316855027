import { html } from "@codemirror/lang-html";
import ReactCodeMirror from "@uiw/react-codemirror";
import { WritableDraft } from "immer/dist/internal";
import { useAtom } from "jotai";
import React, { useCallback } from "react";
import { formAtom } from "../../App";
import { Stack } from "../../components-ui/Stack";
import { FreeTextElement } from "../../types/formTypes";

export function FormFreeTextEditor({ element }: { element: FreeTextElement }) {
  const [, update] = useAtom(formAtom);

  const updateFreeTextElement = useCallback(
    (fn: (element: WritableDraft<FreeTextElement>) => void) => {
      update((formelements) => {
        const foundElement = formelements.find((x) => x.id === element.id);
        if (!foundElement) return;
        if (foundElement.type === "freetext") {
          fn(foundElement);
        }
      });
    },
    [element.id, update]
  );

  const onChangeEnglish = React.useCallback(
    (value, viewUpdate) => {
      if (typeof value === "string") {
        updateFreeTextElement((prev) => {
          prev.html = value;
        });
      }
    },
    [updateFreeTextElement]
  );

  const onChangeFrench = React.useCallback(
    (value, viewUpdate) => {
      if (typeof value === "string") {
        updateFreeTextElement((prev) => {
          prev.html2 = value;
        });
      }
    },
    [updateFreeTextElement]
  );

  return (
    <Stack>
      <div>Html English</div>
      <ReactCodeMirror
        value={element.html}
        height="500px"
        width="100%"
        maxWidth="100%"
        extensions={[html()]}
        onChange={onChangeEnglish}
      />
      <div>Html French</div>
      <ReactCodeMirror
        value={element.html2}
        height="500px"
        width="100%"
        maxWidth="100%"
        extensions={[html()]}
        onChange={onChangeFrench}
      />
    </Stack>
  );
}
