import { WritableDraft } from "immer/dist/internal";
import { InputGroup } from "../../components-ui/InputGroup";
import { Stack } from "../../components-ui/Stack";
import { SwitchGroup } from "../../components-ui/SwitchGroup";
import {
  DateElement,
  FormCheckBoxChildren,
  NumberElement,
  TextElement,
} from "../../types/formTypes";

type Props = {
  editQuestion: (
    fn: (element: WritableDraft<FormCheckBoxChildren>) => void
  ) => void;
  question: FormCheckBoxChildren;
};

export function FormChoiceQuestionEditor({ question, editQuestion }: Props) {
  if (question.type === "text") {
    const editFunctionTextElement = (
      fn: (element: WritableDraft<TextElement>) => void
    ) => {
      editQuestion((prev) => {
        if (prev.type !== "text") return;
        fn(prev);
      });
    };

    return (
      <div>
        <Stack>
          <div>
            <strong>Supplemental Text Question</strong>
          </div>
          <InputGroup
            label={"Label (English)"}
            value={question.label}
            onChange={(val) => {
              editFunctionTextElement((prev) => {
                prev.label = val;
              });
            }}
          ></InputGroup>
          <InputGroup
            label={"Label (French)"}
            value={question.label2}
            onChange={(val) => {
              editFunctionTextElement((prev) => {
                prev.label2 = val;
              });
            }}
          ></InputGroup>
          <SwitchGroup
            label="Display as Long Text"
            checked={question.isLongText}
            onCheckedChange={(newVal) => {
              editFunctionTextElement((prev) => {
                prev.isLongText = newVal;
              });
            }}
          ></SwitchGroup>
        </Stack>
      </div>
    );
  } else if (question.type === "number") {
    const editCurrencyElement = (
      fn: (element: WritableDraft<NumberElement>) => void
    ) => {
      editQuestion((prev) => {
        if (prev.type !== "number") return;
        fn(prev);
      });
    };

    return (
      <div>
        <Stack>
          <div>
            <strong>Supplemental Currency Question</strong>
          </div>
          <InputGroup
            label={"Label (English)"}
            value={question.label}
            onChange={(val) => {
              editCurrencyElement((prev) => {
                prev.label = val;
              });
            }}
          ></InputGroup>
          <InputGroup
            label={"Label (French)"}
            value={question.label2}
            onChange={(val) => {
              editCurrencyElement((prev) => {
                prev.label2 = val;
              });
            }}
          ></InputGroup>
        </Stack>
      </div>
    );
  } else if (question.type === "date") {
    const editDateElement = (
      fn: (element: WritableDraft<DateElement>) => void
    ) => {
      editQuestion((prev) => {
        if (prev.type !== "date") return;
        fn(prev);
      });
    };

    return (
      <div>
        <Stack>
          <div>
            <strong>Supplemental Date Question</strong>
          </div>
          <InputGroup
            label={"Label (English)"}
            value={question.label}
            onChange={(val) => {
              editDateElement((prev) => {
                prev.label = val;
              });
            }}
          ></InputGroup>
          <InputGroup
            label={"Label (French)"}
            value={question.label2}
            onChange={(val) => {
              editDateElement((prev) => {
                prev.label2 = val;
              });
            }}
          ></InputGroup>
          <SwitchGroup
            label="Required"
            checked={!question.isOptional}
            onCheckedChange={(newVal) => {
              editDateElement((prev) => {
                prev.isOptional = !newVal;
              });
            }}
          ></SwitchGroup>
          <SwitchGroup
            label="Must be in the past"
            checked={question.isPastDate}
            onCheckedChange={(newVal) => {
              editDateElement((prev) => {
                prev.isPastDate = newVal;

                if (newVal) {
                  prev.isFutureDate = false;
                }
              });
            }}
          ></SwitchGroup>
          <SwitchGroup
            label="Must be in the future"
            checked={question.isFutureDate}
            onCheckedChange={(newVal) => {
              editDateElement((prev) => {
                prev.isFutureDate = newVal;

                if (newVal) {
                  prev.isPastDate = false;
                }
              });
            }}
          ></SwitchGroup>
        </Stack>
      </div>
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const exhaustiveCheck: never = question;
    return null;
  }
}
