import { WritableDraft } from "immer/dist/internal";
import { useAtom } from "jotai";
import { nanoid } from "nanoid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { formAtom } from "../../App";
import { Button } from "../../components-ui/Button";
import { IconButton } from "../../components-ui/IconButton";
import { InputGroup } from "../../components-ui/InputGroup";
import { Stack } from "../../components-ui/Stack";
import { SwitchGroup } from "../../components-ui/SwitchGroup";
import { FormCheckBoxChoice, FormCheckbox } from "../../types/formTypes";
import { FormCheckBoxChoiceEditor } from "./FormCheckBoxChoiceEditor";

export function FormCheckBoxEditor({ element }: { element: FormCheckbox }) {
  const [, update] = useAtom(formAtom);

  const updateCheckBoxElement = (
    fn: (element: WritableDraft<FormCheckbox>) => void
  ) => {
    update((formelements) => {
      const foundElement = formelements.find((x) => x.id === element.id);
      if (!foundElement) return;
      if (foundElement.type === "checkbox") {
        fn(foundElement);
      }
    });
  };

  const addChoice = () => {
    updateCheckBoxElement((elem) => {
      const id = nanoid();
      elem.choices.push({
        id: id,
        safeId: id,
        label: "Label",
        label2: "Label",
        children: [],
        helplabel: "",
        helplabel2: "",
      });
    });
  };

  return (
    <Stack>
      <InputGroup
        label={"Label (English)"}
        value={element.label}
        onChange={(val) => {
          updateCheckBoxElement((prev) => {
            prev.label = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Label (French)"}
        value={element.label2}
        onChange={(val) => {
          updateCheckBoxElement((prev) => {
            prev.label2 = val;
          });
        }}
      ></InputGroup>
      <div>
        <Button variant="main" onClick={addChoice}>
          Add Option
        </Button>
      </div>
      <DragDropContext
        onDragEnd={(result) => {
          updateCheckBoxElement((previousValues) => {
            if (result.destination) {
              const [reorderedItem] = previousValues.choices.splice(
                result.source.index,
                1
              );
              previousValues.choices.splice(
                result.destination.index,
                0,
                reorderedItem
              );
            }
          });
        }}
      >
        <Droppable droppableId="listofelements2">
          {(provided, snapchot) => (
            <ListOfChoicesZone
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Stack style={{ position: "relative" }}>
                {element.choices.map((x, i) => {
                  const updateChoiceElement = (
                    fn: (element: WritableDraft<FormCheckBoxChoice>) => void
                  ) => {
                    updateCheckBoxElement((checkBoxElement) => {
                      const foundChoice = checkBoxElement.choices.find(
                        (c) => c.id === x.id
                      );
                      if (!foundChoice) return;
                      fn(foundChoice);
                    });
                  };

                  const deleteChoiceElement = (index: number) => {
                    updateCheckBoxElement((checkboxelement) => {
                      checkboxelement.choices.splice(index, 1);
                    });
                  };
                  return (
                    <Draggable key={x.id} draggableId={x.id} index={i}>
                      {(draggableProvided, snap) => {
                        return (
                          <ElementZone
                            key={x.id}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            <IconButton
                              buttonKey="move"
                              {...draggableProvided.dragHandleProps}
                            ></IconButton>

                            <FormCheckBoxChoiceEditor
                              choice={x}
                              updateChoice={updateChoiceElement}
                            ></FormCheckBoxChoiceEditor>
                            {element.choices.length > 1 && (
                              <IconButton
                                buttonKey="trash"
                                onClick={() => {
                                  deleteChoiceElement(i);
                                }}
                              ></IconButton>
                            )}
                            {x.label}
                          </ElementZone>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Stack>
            </ListOfChoicesZone>
          )}
        </Droppable>
      </DragDropContext>

      <SwitchGroup
        label="Must choose at least one"
        checked={element.mustChooseAtLeastOne}
        onCheckedChange={(newVal) => {
          updateCheckBoxElement((prev) => {
            prev.mustChooseAtLeastOne = newVal;
          });
        }}
      ></SwitchGroup>
    </Stack>
  );
}

const ListOfChoicesZone = styled.div`
  background-color: white
  padding: 15px;
  border-radius: 3px;
`;

const ElementZone = styled.div`
  background-color: var(--color-dark-background);
  padding: 10px;
`;
