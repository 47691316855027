import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import styled, { css } from "styled-components";
import { FormElement } from "../types/formTypes";
import { HeadingElementDisplay } from "./HeadingElementDisplay";
import { InputElement } from "./InputElement";

import Arrows from "@heroicons/react/24/outline/ArrowsPointingOutIcon";

import Pencil from "@heroicons/react/24/outline/PencilIcon";

import Trash from "@heroicons/react/24/outline/TrashIcon";
import { useAtom } from "jotai";
import { formAtom, isEditedAtom } from "../App";
import { CheckboxElementDisplay } from "./CheckboxElementDisplay";
import { DateElementDisplay } from "./DateElementDisplay";
import { FreeTextElementDisplay } from "./FreeTextElementDisplay";
import { ListElementDisplay } from "./ListELementDisplay";
import { NumberELementDisplay } from "./NumberElementDisplay";

const ElementDisplayerContainer = styled.div<{ isDraggedDisabled?: boolean }>`
  display: flex;
  gap: 10px;

  &:not(:hover) > [data-handle="true"] {
    visibility: hidden;
  }

  &:not(:hover) > [data-icon="true"] {
    visibility: hidden;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  ${({ isDraggedDisabled }) => {
    if (isDraggedDisabled) {
      return css`
        > [data-handle="true"] {
          visibility: hidden;
        }

        &:hover {
          background-color: inherit;
        }
      `;
    }
  }}
`;

const HandleContainer = styled.div`
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  svg {
    color: #000000a7;
  }

  &:hover {
    svg {
      color: black;
    }
  }
`;

const PencilContainer = styled.div`
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;

  svg {
    color: #000000a7;
  }

  &:hover {
    svg {
      color: black;
    }
  }
  cursor: pointer;
`;

const Handle = styled.div<{ isVisuallyHidden?: boolean }>`
  height: 25px;
  width: 25px;

  ${(props) => {
    if (props.isVisuallyHidden) {
      return css`
        visibility: hidden;
      `;
    }
  }}
`;

const PencilIconContainer = styled.div<{ isVisuallyHidden?: boolean }>`
  height: 25px;
  width: 25px;

  ${(props) => {
    if (props.isVisuallyHidden) {
      return css`
        visibility: hidden;
      `;
    }
  }}
`;

const ContentContainer = styled.div`
  flex-grow: 1;

  padding: 10px;
`;

export function ElementDisplayer({
  element,
  draggHandleProps,
  isDragActive,
  isDragged,
}: {
  element: FormElement;
  draggHandleProps: DraggableProvidedDragHandleProps | undefined;
  isDragged: boolean;
  isDragActive: boolean;
}) {
  const [, update] = useAtom(formAtom);

  const deleteElement = (id: string) => {
    update((prev) => {
      return prev.filter((x) => x.id !== id);
    });
  };

  const [, setEdited] = useAtom(isEditedAtom);
  return (
    <ElementDisplayerContainer isDraggedDisabled={!isDragged && isDragActive}>
      <HandleContainer data-handle={"true"}>
        <Handle {...draggHandleProps} isVisuallyHidden={isDragActive}>
          <Arrows></Arrows>
        </Handle>
      </HandleContainer>
      <PencilContainer
        onClick={() => {
          setEdited(element.id);
        }}
        data-icon={"true"}
      >
        <PencilIconContainer isVisuallyHidden={isDragActive}>
          <Pencil></Pencil>
        </PencilIconContainer>
      </PencilContainer>
      <PencilContainer
        onClick={() => {
          deleteElement(element.id);
        }}
        data-icon={"true"}
      >
        <PencilIconContainer isVisuallyHidden={isDragActive}>
          <Trash></Trash>
        </PencilIconContainer>
      </PencilContainer>
      <ContentContainer>
        <ElementContentSelector
          isDragged={isDragged}
          isDragActive={isDragActive}
          element={element}
        ></ElementContentSelector>
      </ContentContainer>
    </ElementDisplayerContainer>
  );
}

function ElementContentSelector({
  element,
}: {
  element: FormElement;
  isDragged: boolean;
  isDragActive: boolean;
}) {
  if (element.type === "text") {
    return <InputElement element={element}></InputElement>;
  } else if (element.type === "heading") {
    return <HeadingElementDisplay element={element}></HeadingElementDisplay>;
  } else if (element.type === "checkbox") {
    return <CheckboxElementDisplay element={element}></CheckboxElementDisplay>;
  } else if (element.type === "list") {
    return <ListElementDisplay element={element}></ListElementDisplay>;
  } else if (element.type === "freetext") {
    return <FreeTextElementDisplay element={element}></FreeTextElementDisplay>;
  } else if (element.type === "date") {
    return <DateElementDisplay element={element}></DateElementDisplay>;
  } else if (element.type === "number") {
    return <NumberELementDisplay element={element}></NumberELementDisplay>;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const exhaustiveCheck: never = element;
  }
  return <div>Not Implemented</div>;
}
