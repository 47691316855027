import Arrows from "@heroicons/react/24/outline/ArrowsPointingOutIcon";
import Pencil from "@heroicons/react/24/outline/PencilIcon";
import Trash from "@heroicons/react/24/outline/TrashIcon";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
type ButtonKey = "move" | "pencil" | "trash";

type Props = {
  buttonKey: ButtonKey;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type Ref = HTMLButtonElement;

export const IconButton = React.forwardRef<Ref, Props>((props, ref) => {
  const { buttonKey, ...buttonProps } = props;
  return (
    <StyledIconButton ref={ref} {...buttonProps}>
      <ButtonIconSelector buttonKey={props.buttonKey}></ButtonIconSelector>
    </StyledIconButton>
  );
});

function ButtonIconSelector({ buttonKey }: { buttonKey: ButtonKey }) {
  if (buttonKey === "move") {
    return <Arrows></Arrows>;
  } else if (buttonKey === "pencil") {
    return <Pencil></Pencil>;
  } else if (buttonKey === "trash") {
    return <Trash></Trash>;
  } else {
    return null;
  }
}

const StyledIconButton = styled.button`
  display: inline-flex;
  height: 30px;
  width: 30px;
  vertical-align: middle;
  margin-right: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;

  svg {
    color: #000000a7;
  }

  &:hover {
    svg {
      color: black;
    }
  }
`;
