import styled from "styled-components";

type Props = {
  isDisplayed: boolean;
};

export function Asterisk({ isDisplayed }: Props) {
  if (isDisplayed) {
    return <AsteriskSpan>* </AsteriskSpan>;
  }
  return null;
}

const AsteriskSpan = styled.span`
  color: var(--color-main);
`;
