import styled from "styled-components";
import { ConditionTag } from "../components-ui/LabelTag";
import { Stack } from "../components-ui/Stack";
import type { FormCheckbox, FormCheckBoxChildren } from "../types/formTypes";

export function CheckboxElementDisplay({ element }: { element: FormCheckbox }) {
  return (
    <div>
      <Label>
        {element.label}
        {element.condition !== undefined && <ConditionTag></ConditionTag>}
      </Label>
      <Stack>
        {element.choices.map((c) => {
          return (
            <ChoiceContainer key={c.id}>
              <FakeChekboxContainer>
                <FakeCheckBox></FakeCheckBox>
              </FakeChekboxContainer>
              <LabelContainer>
                <div> {c.label} </div>
                {c.children.slice(0, 2).length > 0 && (
                  <SupplementalQuestionConainer>
                    <DisplaySupplementalQuestion
                      element={c.children[0]}
                    ></DisplaySupplementalQuestion>
                  </SupplementalQuestionConainer>
                )}
              </LabelContainer>
            </ChoiceContainer>
          );
        })}
        {element.choices.length > 3 && <ChoiceContainer>...</ChoiceContainer>}
      </Stack>
    </div>
  );
}

const Label = styled.div`
  margin-bottom: 5px;
`;

const ChoiceContainer = styled.div`
  background-color: var(--color-dark-background);
  padding: 10px;
  border-radius: 0.5rem;
  max-width: 500px;
  display: flex;
`;

const FakeCheckBox = styled.div`
  display: inline-block;
  --fakecheckboxsize: 20px;
  vertical-align: -0.3rem;
  margin-right: 10px;
  border: 1px solid black;
  height: var(--fakecheckboxsize);
  width: var(--fakecheckboxsize);
  border-radius: 2px;
`;

const FakeChekboxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SupplementalQuestionConainer = styled.div`
  font-size: 0.9em;
`;

function DisplaySupplementalQuestion({
  element,
}: {
  element: FormCheckBoxChildren;
}) {
  if (element.type === "text") {
    return <>{element.label}</>;
  } else if (element.type === "number") {
    return <>{element.label}</>;
  } else if (element.type === "date") {
    return <>{element.label}</>;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const exhaustiveCheck: never = element;
    return null;
  }
}
