import styled from "styled-components";
import { ConditionTag } from "../components-ui/LabelTag";
import type { HeadingElement } from "../types/formTypes";

export function HeadingElementDisplay({
  element,
}: {
  element: HeadingElement;
}) {
  return (
    <div>
      <Text>{element.content}</Text>
      {element.condition !== undefined && <ConditionTag></ConditionTag>}
    </div>
  );
}

const Text = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;
