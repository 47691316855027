import styled from "styled-components";
import { Asterisk } from "../components-ui/Asterisk";
import { ConditionTag, LabelTagHelp } from "../components-ui/LabelTag";
import type { NumberElement } from "../types/formTypes";

export function NumberELementDisplay({ element }: { element: NumberElement }) {
  return (
    <div>
      <Label>
        <Asterisk isDisplayed={!element.isOptional}></Asterisk>
        {element.currency ? "$ " : "(Number) "}
        {element.label}
        {element.helplabel !== "" && <LabelTagHelp></LabelTagHelp>}
        {element.condition !== undefined && <ConditionTag></ConditionTag>}
      </Label>
      <FakeInput></FakeInput>
    </div>
  );
}

const FakeInput = styled.div`
  height: 40px;
  background: white;
  border-radius: 0.5rem;
  border: 2px solid #e2e6e9;
  max-width: 500px;
`;

const Label = styled.div`
  margin-bottom: 5px;
`;
