import { Provider as JotaiProvider, useAtom } from "jotai";
import { atomWithImmer } from "jotai-immer";
import { nanoid } from "nanoid";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Button } from "./components-ui/Button";
import { InputGroup } from "./components-ui/InputGroup";
import { NewFormElement } from "./components-ui/NewFormElement";
import { ElementDisplayer } from "./components/ElementDisplayer";
import { ModalEditor } from "./components/ModalEditor";
import { newToOld } from "./formconverter/newToOld";
import { convertOld } from "./formconverter/oldtonew";
import { FormElement } from "./types/formTypes";
const Test = styled.div`
  background-color: white;
`;

const Stack = styled.div<{ isDraggingHover?: boolean }>`
  margin: 0 10px;
  border-radius: 7px;
  > * + * {
    margin-top: 5px;
  }

  margin-top: 10px;
`;

const initialState: FormElement[] = [];
const formTitleInitialState: {
  title1: string;
  title2: string;
} = {
  title1: "",
  title2: "",
};
export const formAtom = atomWithImmer(initialState);
export const formTitleAtom = atomWithImmer(formTitleInitialState);
export const isEditedAtom = atomWithImmer<null | string>(null);

const MainFlexContainer = styled.div`
  width: 100%;
  display: flex;
  background: var(--color-app-background);
  height: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 20px;
  gap: 20px;
  justify-content: center;
`;

function parseJson(str: string) {
  try {
    const result: unknown = JSON.parse(str);
    console.log("was parsed");
    return { isValid: true, result: result };
  } catch {
    return { isValid: false };
  }
}

const StyledTextArea = styled.textarea`
  margin: auto;
  height: 500px;
  width: 500px;
  resize: none;
`;

const OuterDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  flex-direction: column;
  gap: 10px;
`;

export default function App() {
  const [state, setState] = useState("");

  if (state !== "") {
    const parsedResult = parseJson(state);
    if (parsedResult.isValid) {
      console.log("valid :)");
      const parsedSchema = convertOld({ old: parsedResult.result });
      console.log("BOOM", parsedSchema.isValid);
      if (parsedSchema.isValid) {
        return (
          <JsonStateProvider
            state={parsedSchema.form}
            title1={parsedSchema.title1}
            title2={parsedSchema.title2}
          >
            <CoolJsonEditor
              exit={() => {
                setState("");
              }}
            ></CoolJsonEditor>
          </JsonStateProvider>
        );
      }
    }
  }

  return (
    <OuterDiv>
      <div style={{ width: "500px", margin: "auto" }}>Paste Json</div>
      <StyledTextArea
        value={state}
        onChange={(e) => {
          setState(e.target.value);
        }}
        placeholder="Please paste json"
      ></StyledTextArea>
    </OuterDiv>
  );
}

function JsonStateProvider({
  state,
  children,
  title1,
  title2,
}: {
  state: FormElement[];
  title1: string;
  title2: string;
  children: React.ReactNode;
}) {
  return (
    <JotaiProvider
      initialValues={[
        [formAtom, state],
        [formTitleAtom, { title1: title1, title2: title2 }],
      ]}
    >
      {children}
    </JotaiProvider>
  );
}

export function CoolJsonEditor({ exit }: { exit: () => unknown }) {
  const [state, update] = useAtom(formAtom);
  const [titles, setTitles] = useAtom(formTitleAtom);
  const copyIntialText = "Copy JSON To Clipboard";
  const [copyText, setCopyText] = useState(copyIntialText);
  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          console.log(result);
          if (result.source.droppableId === "text") {
            update((previousValues) => {
              const id = nanoid();
              if (result.destination) {
                previousValues.splice(result.destination.index, 0, {
                  type: "text",
                  id: id,
                  safeId: id,
                  label: "Default label",
                  label2: "Label par défaut",
                  isLongText: false,
                  maxLength: 300,
                  isOptional: false,
                  helplabel: "",
                  helplabel2: "",
                });
              }
            });
          } else if (result.source.droppableId === "currency") {
            update((previousValues) => {
              if (result.destination) {
                const id = nanoid();
                previousValues.splice(result.destination.index, 0, {
                  type: "number",
                  id: id,
                  safeId: id,
                  label: "Default label",
                  label2: "Label par défaut",
                  isOptional: false,
                  helplabel: "",
                  helplabel2: "",
                });
              }
            });
          } else if (result.source.droppableId === "heading") {
            update((previousValues) => {
              if (result.destination) {
                previousValues.splice(result.destination.index, 0, {
                  type: "heading",
                  content: "Heading Label",
                  content2: "Heading Label",
                  id: nanoid(),
                  level: 1,
                });
              }
            });
          } else if (result.source.droppableId === "date") {
            update((previousValues) => {
              if (result.destination) {
                const id = nanoid();
                previousValues.splice(result.destination.index, 0, {
                  type: "date",
                  label: " Label",
                  label2: " Label",
                  id: id,
                  safeId: id,
                  isFutureDate: false,
                  isOptional: false,
                  isPastDate: false,
                  helplabel: "",
                  helplabel2: "",
                });
              }
            });
          } else if (result.source.droppableId === "htmltext") {
            update((previousValues) => {
              if (result.destination) {
                const id = nanoid();
                previousValues.splice(result.destination.index, 0, {
                  type: "freetext",
                  html: "<div>Default label<div>",
                  html2: "<div>Default label<div>",
                  id: id,
                  safeId: id,
                });
              }
            });
          } else if (result.source.droppableId === "listradio") {
            update((previousValues) => {
              if (result.destination) {
                const choice1Id = nanoid();
                const choice2Id = nanoid();
                const mainId = nanoid();
                previousValues.splice(result.destination.index, 0, {
                  helplabel: "",
                  helplabel2: "",
                  type: "list",
                  label: " Label",
                  label2: " Label",
                  id: mainId,
                  safeId: mainId,
                  displayAsRadio: true,
                  choices: [
                    {
                      id: choice1Id,
                      safeId: choice1Id,
                      label: "Yes",
                      label2: "Oui",
                    },
                    {
                      id: choice2Id,
                      safeId: choice2Id,
                      label: "No",
                      label2: "Non",
                    },
                  ],
                });
              }
            });
          } else if (result.source.droppableId === "listcheck") {
            update((previousValues) => {
              if (result.destination) {
                const choice1Id = nanoid();
                const choice2Id = nanoid();
                const mainId = nanoid();
                previousValues.splice(result.destination.index, 0, {
                  type: "checkbox",
                  label: " Label",
                  label2: " Label",
                  id: mainId,
                  safeId: mainId,
                  mustChooseAtLeastOne: true,
                  choices: [
                    {
                      id: choice1Id,
                      safeId: choice1Id,
                      label: "Option 1",
                      label2: "Option 1",
                      children: [],
                      helplabel: "",
                      helplabel2: "",
                    },
                    {
                      id: choice2Id,
                      safeId: choice2Id,
                      label: "Option 2",
                      label2: "Option 2",
                      children: [],
                      helplabel: "",
                      helplabel2: "",
                    },
                  ],
                });
              }
            });
          } else if (result.source.droppableId === "first") {
            update((previousValues) => {
              if (result.destination) {
                const [reorderedItem] = previousValues.splice(
                  result.source.index,
                  1
                );
                previousValues.splice(
                  result.destination.index,
                  0,
                  reorderedItem
                );
              }
            });
          }
        }}
      >
        <ModalEditor></ModalEditor>
        <MainFlexContainer>
          <Elements>
            <DraggableElementContainer>
              <NewFormElement
                iconKey="heading"
                index={2}
                text={"Heading"}
              ></NewFormElement>
              <NewFormElement
                iconKey="text"
                index={312}
                text={"Text"}
              ></NewFormElement>
              <NewFormElement
                iconKey="htmltext"
                index={312}
                text={"Html Text"}
              ></NewFormElement>
              <NewFormElement
                iconKey="date"
                index={3213123}
                text={"Date"}
              ></NewFormElement>
              <NewFormElement
                iconKey="currency"
                index={1}
                text={"Number"}
              ></NewFormElement>
              <NewFormElement
                iconKey="listradio"
                index={5312313}
                text={"Choose Only One"}
              ></NewFormElement>
              <NewFormElement
                iconKey="listcheck"
                index={5}
                text={"Choose Multiple"}
              ></NewFormElement>
            </DraggableElementContainer>
            <FormTitleContainer>
              <div>
                <InputGroup
                  label={"Form Title (English)"}
                  value={titles.title1}
                  onChange={(val) => {
                    setTitles((prev) => {
                      prev.title1 = val;
                    });
                  }}
                ></InputGroup>
              </div>
              <div>
                <InputGroup
                  label={"Form Title (French)"}
                  value={titles.title2}
                  onChange={(val) => {
                    setTitles((prev) => {
                      prev.title2 = val;
                    });
                  }}
                ></InputGroup>
              </div>
            </FormTitleContainer>
            <ButtonsContainer>
              <Button
                variant="main"
                onClick={() => {
                  const text = newToOld({
                    form: state,
                    title1: titles.title1,
                    title2: titles.title2,
                  });

                  navigator.clipboard.writeText(JSON.stringify(text));
                  setCopyText("Copied ✅");

                  setTimeout(() => {
                    setCopyText(copyIntialText);
                  }, 1000);
                }}
              >
                {copyText}
              </Button>
              <Button variant="secondary" onClick={exit}>
                Exit
              </Button>
            </ButtonsContainer>
          </Elements>
          <MainElements>
            <Droppable droppableId="first">
              {(provided, snapchot) => (
                <Stack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDraggingHover={snapchot.isDraggingOver}
                >
                  {state.map((x, i) => {
                    if (!x) return null;
                    return (
                      <Draggable key={x.id} draggableId={x.id} index={i}>
                        {(draggableProvided, snap) => {
                          return (
                            <Test
                              {...draggableProvided.draggableProps}
                              ref={draggableProvided.innerRef}
                            >
                              <ElementDisplayer
                                isDragActive={snapchot.isDraggingOver}
                                isDragged={snap.isDragging}
                                draggHandleProps={
                                  draggableProvided.dragHandleProps
                                }
                                element={x}
                              ></ElementDisplayer>
                            </Test>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </MainElements>
        </MainFlexContainer>
      </DragDropContext>
    </>
  );
}

const Elements = styled.div`
  width: 400px;
  background-color: white;
  flex-shrink: 1;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.5rem;
  overflow: hidden;
`;

const MainElements = styled.div`
  background-color: white;
  flex-basis: 700px;
  flex-grow: 0;
  overflow: auto;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
`;

const DraggableElementContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: white;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
`;

const ButtonsContainer = styled.div`
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  button {
    flex-grow: 1;
  }
  flex-direction: column;
`;

const FormTitleContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
