import { css } from "styled-components";

export const inputStyling = css`
  display: block;
  width: 100%;
  outline: none;
  padding: 10px;
  border-radius: 0.5rem;
  border: 2px solid #e2e6e9;

  &:focus {
    border: 2px solid #8e9092;
  }
`;
