import { BsCalendarDate } from "react-icons/bs";
import styled from "styled-components";
import { Asterisk } from "../components-ui/Asterisk";
import { ConditionTag, LabelTagHelp } from "../components-ui/LabelTag";
import type { DateElement } from "../types/formTypes";

export function DateElementDisplay({ element }: { element: DateElement }) {
  return (
    <div>
      <Label>
        <Asterisk isDisplayed={!element.isOptional}></Asterisk>
        <DateLabel>
          <BsCalendarDate></BsCalendarDate>
        </DateLabel>{" "}
        {element.label}
        {element.helplabel !== "" && <LabelTagHelp></LabelTagHelp>}
        {element.condition !== undefined && <ConditionTag></ConditionTag>}
      </Label>
      <FakeInput></FakeInput>
    </div>
  );
}

const FakeInput = styled.div`
  height: 40px;
  background: white;
  border-radius: 0.5rem;
  border: 2px solid #e2e6e9;
  max-width: 500px;
`;

const Label = styled.div`
  margin-bottom: 5px;
`;

const DateLabel = styled.span`
  vertical-align: -2.5px;
  margin-right: 5px;
`;
