import * as Dialog from "@radix-ui/react-dialog";
import * as Tabs from "@radix-ui/react-tabs";
import { useAtom } from "jotai";
import styled from "styled-components";
import { formAtom, isEditedAtom } from "../App";
import { Button } from "../components-ui/Button";
import { DialogContent, DialogOverlay } from "../components-ui/ModalElements";
import { Stack } from "../components-ui/Stack";
import { FormElement } from "../types/formTypes";
import { ConditionalsEditor } from "./ModalEditor/ConditionalsEditor";
import { FormCheckBoxEditor } from "./ModalEditor/FormCheckBoxEditor";
import { FormDateEditor } from "./ModalEditor/FormDateEditor";
import { FormFreeTextEditor } from "./ModalEditor/FormFreeTextEditor";
import { FormHeadingEditor } from "./ModalEditor/FormHeadingEditor";
import { FormListEditor } from "./ModalEditor/FormListEditor";
import { FormNumberEditor } from "./ModalEditor/FormNumberEditor";
import { FormTextEditor } from "./ModalEditor/FormStringEditor";

export function ModalEditor() {
  const [edited, setEdited] = useAtom(isEditedAtom);
  const [state] = useAtom(formAtom);
  if (edited === null) return null;

  const element = state.find((x) => x.id === edited);

  if (!element) return null;

  const elementIndex = state.findIndex((x) => x.id === edited);

  const conditionableElements = state
    .map((x, i) => {
      if (x.type === "list") {
        return i;
      }
      return undefined;
    })
    .filter(function (x): x is number {
      return x !== undefined;
    });

  const canHaveConditional = conditionableElements.some(
    (x) => x < elementIndex
  );
  return (
    <Dialog.Root
      open
      onOpenChange={(o) => {
        if (!o) {
          setEdited(null);
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Content
          asChild
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <DialogOverlay>
            <DialogContent variant="large">
              <Stack>
                <Tabs.Root className="TabsRoot" defaultValue="editor">
                  <Tabs.List
                    className="TabsList"
                    aria-label="Manage your account"
                    asChild
                  >
                    <TabContainer>
                      <Tabs.Trigger
                        className="TabsTrigger"
                        value="editor"
                        asChild
                      >
                        <TabButton>Editor</TabButton>
                      </Tabs.Trigger>
                      {canHaveConditional && (
                        <Tabs.Trigger
                          className="TabsTrigger"
                          value="conditional"
                          asChild
                        >
                          <TabButton>Conditional</TabButton>
                        </Tabs.Trigger>
                      )}
                    </TabContainer>
                  </Tabs.List>
                  <Tabs.Content className="TabsContent" value="editor">
                    <ModalEditorSelector
                      element={element}
                    ></ModalEditorSelector>
                  </Tabs.Content>
                  <Tabs.Content className="TabsContent" value="conditional">
                    <ConditionalsEditor></ConditionalsEditor>
                  </Tabs.Content>
                </Tabs.Root>
              </Stack>
              <div>
                <Dialog.Close asChild>
                  <Button variant="secondary">Close</Button>
                </Dialog.Close>
              </div>
            </DialogContent>
          </DialogOverlay>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function ModalEditorSelector({ element }: { element: FormElement }) {
  if (element.type === "text") {
    return <FormTextEditor element={element}></FormTextEditor>;
  } else if (element.type === "checkbox") {
    return <FormCheckBoxEditor element={element}></FormCheckBoxEditor>;
  } else if (element.type === "freetext") {
    return <FormFreeTextEditor element={element}></FormFreeTextEditor>;
  } else if (element.type === "list") {
    return <FormListEditor element={element}></FormListEditor>;
  } else if (element.type === "date") {
    return <FormDateEditor element={element}></FormDateEditor>;
  } else if (element.type === "number") {
    return <FormNumberEditor element={element}></FormNumberEditor>;
  } else if (element.type === "heading") {
    return <FormHeadingEditor element={element}></FormHeadingEditor>;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const exhaustiveCheck: never = element;
  }
  return <div>Unsupported</div>;
}

const TabContainer = styled.div`
  border: 1px solid #ffffff;
  border-radius: 0.5rem;
  margin: auto;
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
`;

const TabButton = styled.button`
  padding: 10px;
  border: none;
  flex-grow: 1;
  &[data-state="active"] {
    background-color: var(--color-main);
    color: white;
  }
  cursor: pointer;
`;
