import React from "react";
import styled from "styled-components";
import { inputStyling } from "./shared";

export const StyledInput = styled.input`
  ${inputStyling}
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  padding-left: 1px;
`;

type Props = {
  onChange: (newVal: string) => void;
  value: string;
  label: string;
};

export const InputGroup = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    return (
      <div>
        <StyledLabel>{props.label}</StyledLabel>
        <StyledInput
          ref={ref}
          value={props.value}
          onChange={(e) => {
            const val = e.target.value;
            props.onChange(val);
          }}
        />
      </div>
    );
  }
);
