type Conditionable = {
  condition?: FormCondition;
};

export type FormCondition = {
  id: string;
  valueId: string;
};

export type HeadingElement = {
  id: string;
  type: "heading";
  level: number;
  content: string;
  content2: string;
} & Conditionable;

export type TextElement = {
  id: string;
  safeId: string;
  type: "text";
  label: string;
  label2: string;
  isLongText: boolean;
  maxLength: number;
  isOptional: boolean;
  helplabel: string;
  helplabel2: string;
  mask?: string;
  doNotPrefillInCounterProposal?: boolean;
} & Conditionable;

export type NumberElement = {
  id: string;
  safeId: string;
  type: "number";
  label: string;
  label2: string;
  isOptional: boolean;
  helplabel: string;
  helplabel2: string;
  currency?: {
    currencyAscii: "$";
    maxNumber: number;
  };
} & Conditionable;

export type ListElement = {
  id: string;
  safeId: string;
  type: "list";
  label: string;
  label2: string;
  choices: ListElementChoice[];
  helplabel: string;
  helplabel2: string;
  displayAsRadio: boolean;
} & Conditionable;

export type FreeTextElement = {
  id: string;
  safeId: string;
  type: "freetext";
  html: string;
  html2: string;
} & Conditionable;

export type ListElementChoice = {
  label: string;
  label2: string;
  id: string;
  safeId: string;
};

export type FormCheckBoxChoice = {
  label: string;
  label2: string;
  children: FormCheckBoxChildren[];
  id: string;
  safeId: string;
  helplabel: string;
  helplabel2: string;
};

export type FormCheckbox = {
  type: "checkbox";
  id: string;
  safeId: string;
  label: string;
  label2: string;
  choices: FormCheckBoxChoice[];
  mustChooseAtLeastOne: boolean;
} & Conditionable;

export type DateElement = {
  id: string;
  safeId: string;
  type: "date";
  label: string;
  label2: string;
  isFutureDate: boolean;
  isPastDate: boolean;
  isOptional: boolean;
  helplabel: string;
  helplabel2: string;
} & Conditionable;

export type HtmlElement = {
  id: string;

  type: "html";
} & Conditionable;

export type FormElement =
  | TextElement
  | HeadingElement
  | FormCheckbox
  | ListElement
  | FreeTextElement
  | DateElement
  | NumberElement;

export type FormCheckBoxChildren = TextElement | DateElement | NumberElement;

export function isFormCheckBoxChild(
  element: FormElement
): element is FormCheckBoxChildren {
  return (
    element.type === "text" ||
    element.type === "date" ||
    element.type === "number"
  );
}
