import { z } from "zod";

export const ZConditionObj = z.object({
  id: z.string(),
  operator: z.literal("eq"),
  value: z.string(),
});

export type TConditionObj = z.infer<typeof ZConditionObj>;

const Zconditions = z.union([z.null(), ZConditionObj]);
const Zparent = z.union([z.string(), z.null()]);
const ZHelp = z.string().optional();

export const ZOldSchema = z.object({
  title: z.string(),
  translations: z.object({
    en: z.record(z.string()),
    fr: z.record(z.string()),
  }),
  fields: z.array(
    z.discriminatedUnion("type", [
      z.object({
        type: z.literal("floatNumber"),
        id: z.string(),
        parent: Zparent,
        title: z.string(),
        conditions: Zconditions,
        help: ZHelp,
        optional: z.boolean().optional(),
        currency: z
          .object({
            currencyAscii: z.literal("$"),
            maxNumber: z.number(),
          })
          .optional(),
      }),
      z.object({
        type: z.literal("string"),
        conditions: Zconditions,
        id: z.string(),
        parent: Zparent,
        title: z.string(),
        ui: z.union([
          z.literal("input"),
          z.literal("textarea"),
          z.literal("currency"),
          z.literal("date"),
          z.literal("mask"),
        ]),
        maxLength: z.number().optional(),
        optional: z.boolean().optional(),
        help: ZHelp,
        mask: z.string().optional(),
        doNotPrefillInCounterProposal: z.boolean().optional(),
        isFutureDate: z.boolean().optional(),
        isPastDate: z.boolean().optional(),
      }),
      z.object({
        type: z.literal("checkbox"),
        conditions: Zconditions,
        id: z.string(),
        parent: Zparent,
        title: z.string(),
        optional: z.boolean().optional(),
        elements: z.array(
          z.object({
            id: z.string(),
            title: z.string(),
            help: ZHelp,
          })
        ),
      }),
      z.object({
        type: z.literal("list"),
        conditions: Zconditions,
        id: z.string(),
        parent: Zparent,
        title: z.string(),
        elements: z.array(z.string()),
        help: ZHelp,
        ui: z.literal("radio").optional(),
      }),
      z.object({
        type: z.literal("freeText"),
        conditions: Zconditions,
        id: z.string(),
        parent: Zparent,
        content: z.string(),
      }),
      z.object({
        type: z.literal("object"),
        conditions: Zconditions,
        id: z.string(),
        parent: Zparent,
        title: z.string(),
      }),
    ])
  ),
});

export type OldSchema = z.infer<typeof ZOldSchema>;
