import { useAtom } from "jotai";
import Select from "react-select";
import styled from "styled-components";
import { formAtom, isEditedAtom } from "../../App";
import { Button } from "../../components-ui/Button";
import { Stack } from "../../components-ui/Stack";
import { ListElement } from "../../types/formTypes";

type SelectOption = {
  label: string;
  value: string;
};

type SelectOptions = SelectOption[];

export function ConditionalsEditor() {
  const [state, update] = useAtom(formAtom);
  const [edited] = useAtom(isEditedAtom);

  function addConditional(id: string, valueId: string) {
    update((draft) => {
      const foundElement = draft.find((x) => x.id === edited);

      if (foundElement) {
        foundElement.condition = {
          id: id,
          valueId: valueId,
        };
      }
    });
  }

  function removeConditional() {
    update((draft) => {
      const foundElement = draft.find((x) => x.id === edited);

      if (foundElement) {
        foundElement.condition = undefined;
      }
    });
  }

  const element = state.find((x) => x.id === edited);

  if (!element) return null;

  const elementIndex = state.findIndex((x) => x.id === edited);

  const conditionableElements = state
    .map((x, i) => {
      if (x.type === "list" && i < elementIndex) {
        return x;
      }
      return undefined;
    })
    .filter(function (x): x is ListElement {
      return x !== undefined;
    });

  const options: SelectOptions = conditionableElements.map((x) => {
    return {
      label: x.label,
      value: x.id,
    };
  });

  const foundSelectedOption = options.find(
    (x) => x.value === element.condition?.id
  );

  const selectFragment = (
    <div>
      <StyledLabel>Display when user answered the field</StyledLabel>
      <Select
        onChange={(newVal) => {
          if (newVal) {
            const conditionableElement = conditionableElements.find(
              (x) => x.id === newVal.value
            );

            if (conditionableElement) {
              if (conditionableElement.choices.length > 0) {
                addConditional(
                  conditionableElement.id,
                  conditionableElement.choices[0].id
                );
              }
            }
          } else {
            removeConditional();
          }
        }}
        value={foundSelectedOption ?? null}
        options={options}
      />
    </div>
  );

  let optionFragment: React.ReactElement = <></>;

  if (foundSelectedOption) {
    const optionSelected = conditionableElements.find(
      (x) => x.id === foundSelectedOption.value
    );

    if (optionSelected) {
      const innerOptions: SelectOptions = optionSelected.choices.map((x) => {
        return {
          label: x.label,
          value: x.id,
        };
      });

      const selectedInnerOption = innerOptions.find(
        (x) => x.value === element.condition?.valueId
      );

      optionFragment = (
        <>
          <div>
            <StyledLabel>And selected the option</StyledLabel>
            <Select
              onChange={(newVal) => {
                if (newVal) {
                  addConditional(foundSelectedOption.value, newVal.value);
                }
              }}
              value={selectedInnerOption ?? null}
              options={innerOptions}
            />
          </div>
          <div>
            <Button variant="secondary" onClick={removeConditional}>
              Remove Condition
            </Button>
          </div>
        </>
      );
    }
  }

  return (
    <Stack>
      {selectFragment} {optionFragment}
    </Stack>
  );
}

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  padding-left: 1px;
`;
