import * as RadixSwitch from "@radix-ui/react-switch";

import styled from "styled-components";

const StyledSwitch = styled(RadixSwitch.Root)({
  all: "unset",
  padding: "2px",
  width: 42,
  height: 25,
  backgroundColor: "#B5C1C8",
  borderRadius: "9999px",
  position: "relative",
  outline: "none",
  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
  "&:hover": { cursor: `pointer` },
  "&:focus-visible": { boxShadow: `0 0 0 5px blue` },
  '&[data-state="checked"]': { backgroundColor: "var(--color-main)" },
});

const StyledThumb = styled(RadixSwitch.Thumb)({
  display: "block",
  width: 21,
  height: 21,
  outline: "none",
  backgroundColor: "white",
  borderRadius: "9999px",
  transition: "transform 100ms",
  transform: "translateX(2px)",
  willChange: "transform",
  '&[data-state="checked"]': { transform: "translateX(19px)" },
});

type Props = {
  checked: boolean;
  onCheckedChange: (val: boolean) => void;
  label: string;
};

export const SwitchGroup = (props: Props) => {
  return (
    <div>
      <SwitchLabel>{props.label} </SwitchLabel>
      <StyledSwitch
        checked={props.checked}
        onCheckedChange={props.onCheckedChange}
      >
        <StyledThumb></StyledThumb>
      </StyledSwitch>
    </div>
  );
};

const SwitchLabel = styled.div`
  display: inline;
  vertical-align: 5px;
  margin-right: 2px;
`;
