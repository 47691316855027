import { nanoid } from "nanoid";
import { FormCondition, FormElement } from "../types/formTypes";
import { OldSchema, TConditionObj } from "./ZodSchemas";

function extractFormConditon(
  condition: FormCondition | undefined
): TConditionObj | null {
  if (condition) {
    return {
      id: condition.id,
      operator: "eq",
      value: condition.valueId,
    };
  } else {
    return null;
  }
}

export function newToOld(data: {
  form: FormElement[];
  title1: string;
  title2: string;
}): OldSchema {
  const translations: {
    en: Record<string, string>;
    fr: Record<string, string>;
  } = { en: {}, fr: {} };

  function addTranslation(id: string, english: string, french: string) {
    translations.en[id] = english;
    translations.fr[id] = french;
  }

  const formTitleId = nanoid();

  addTranslation(formTitleId, data.title1, data.title2);
  let idCursor: string | null = null;

  const result: OldSchema = {
    fields: [],
    title: formTitleId,
    translations: translations,
  };

  data.form.forEach((e) => {
    if (e.type === "text") {
      const helpId = nanoid();
      const titleId = nanoid();
      addTranslation(helpId, e.helplabel, e.helplabel2);
      addTranslation(titleId, e.label, e.label2);
      result.fields.push({
        type: "string",
        conditions: extractFormConditon(e.condition),
        id: e.safeId,
        parent: idCursor,
        title: titleId,
        ui: e.mask ? "mask" : e.isLongText ? "textarea" : "input",
        doNotPrefillInCounterProposal: e.doNotPrefillInCounterProposal,
        help: helpId,
        optional: e.isOptional,
        mask: e.mask ? e.mask : undefined,
        maxLength: e.maxLength,
      });
    } else if (e.type === "date") {
      const helpId = nanoid();
      const titleId = nanoid();
      addTranslation(helpId, e.helplabel, e.helplabel2);
      addTranslation(titleId, e.label, e.label2);
      result.fields.push({
        type: "string",
        conditions: extractFormConditon(e.condition),
        id: e.safeId,
        parent: idCursor,
        title: titleId,
        ui: "date",
        doNotPrefillInCounterProposal: false,
        help: helpId,
        optional: e.isOptional,
        isFutureDate: e.isFutureDate,
        isPastDate: e.isPastDate,
      });
    } else if (e.type === "number") {
      const helpId = nanoid();
      const titleId = nanoid();
      addTranslation(helpId, e.helplabel, e.helplabel2);
      addTranslation(titleId, e.label, e.label2);
      result.fields.push({
        type: "floatNumber",
        conditions: extractFormConditon(e.condition),
        id: e.safeId,
        parent: idCursor,
        title: titleId,
        help: helpId,
        optional: e.isOptional,
        currency: e.currency
          ? {
              currencyAscii: "$",
              maxNumber: e.currency.maxNumber,
            }
          : undefined,
      });
    } else if (e.type === "heading") {
      const titleId = nanoid();
      addTranslation(titleId, e.content, e.content2);
      result.fields.push({
        type: "object",
        conditions: extractFormConditon(e.condition),
        id: e.id,
        parent: idCursor,
        title: titleId,
      });

      idCursor = e.id;
    } else if (e.type === "freetext") {
      const contentId = nanoid();
      addTranslation(contentId, e.html, e.html2);
      result.fields.push({
        type: "freeText",
        conditions: extractFormConditon(e.condition),
        id: e.safeId,
        parent: idCursor,
        content: contentId,
      });
    } else if (e.type === "list") {
      const helpId = nanoid();
      const titleId = nanoid();
      addTranslation(helpId, e.helplabel, e.helplabel2);
      addTranslation(titleId, e.label, e.label2);
      result.fields.push({
        type: "list",
        conditions: extractFormConditon(e.condition),
        id: e.safeId,
        parent: idCursor,
        ui: e.displayAsRadio ? "radio" : undefined,
        elements: e.choices.map((x) => {
          addTranslation(x.safeId, x.label, x.label2);
          return x.safeId;
        }),
        title: titleId,
        help: helpId,
      });
    } else if (e.type === "checkbox") {
      const titleId = nanoid();
      addTranslation(titleId, e.label, e.label2);
      result.fields.push({
        type: "checkbox",
        conditions: extractFormConditon(e.condition),
        id: e.safeId,
        parent: idCursor,
        optional: !e.mustChooseAtLeastOne,
        elements: e.choices.map((x) => {
          const choiceHelpId = nanoid();
          addTranslation(choiceHelpId, x.helplabel, x.helplabel2);
          addTranslation(x.safeId, x.label, x.label2);
          x.children.forEach((c) => {
            const parentId = x.safeId;
            const childId = c.safeId;
            if (c.type === "text") {
              const helpId = nanoid();
              const titleId = nanoid();
              addTranslation(helpId, c.helplabel, c.helplabel2);
              addTranslation(titleId, c.label, c.label2);
              result.fields.push({
                type: "string",
                conditions: extractFormConditon(c.condition),
                id: childId,
                parent: parentId,
                title: titleId,
                ui: c.mask ? "mask" : c.isLongText ? "textarea" : "input",
                doNotPrefillInCounterProposal: c.doNotPrefillInCounterProposal,
                help: helpId,
                optional: c.isOptional,
                mask: c.mask ? c.mask : undefined,
                maxLength: c.maxLength,
              });
            } else if (c.type === "date") {
              const helpId = nanoid();
              const titleId = nanoid();
              addTranslation(helpId, c.helplabel, c.helplabel2);
              addTranslation(titleId, c.label, c.label2);
              result.fields.push({
                type: "string",
                conditions: extractFormConditon(c.condition),
                id: childId,
                parent: parentId,
                title: titleId,
                ui: "date",
                doNotPrefillInCounterProposal: false,
                help: helpId,
                optional: c.isOptional,
                isFutureDate: c.isFutureDate,
                isPastDate: c.isPastDate,
              });
            } else if (c.type === "number") {
              const helpId = nanoid();
              const titleId = nanoid();
              addTranslation(helpId, c.helplabel, c.helplabel2);
              addTranslation(titleId, c.label, c.label2);
              result.fields.push({
                type: "floatNumber",
                conditions: extractFormConditon(c.condition),
                id: childId,
                parent: parentId,
                title: titleId,
                help: helpId,
                optional: c.isOptional,
                currency: c.currency
                  ? {
                      currencyAscii: "$",
                      maxNumber: c.currency.maxNumber,
                    }
                  : undefined,
              });
            }
          });
          return { id: x.safeId, title: x.safeId, help: choiceHelpId };
        }),
        title: titleId,
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = e;
    }
  });

  return result;
}
