import { ConditionTag } from "../components-ui/LabelTag";
import { FreeTextElement } from "../types/formTypes";
import { isHTML } from "../utils/isHtml";

export function FreeTextElementDisplay({
  element,
}: {
  element: FreeTextElement;
}) {
  if (!isHTML(element.html)) {
    return null;
  }
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: element.html }}></div>
      {element.condition !== undefined && <ConditionTag></ConditionTag>}
    </div>
  );
}
