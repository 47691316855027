import React from "react";
import useMeasure from "react-use-measure";
import styled, { css } from "styled-components";

export type DialogContentVariant = "large" | "small";

export const StyledDialogContent = styled.div<{
  variant: DialogContentVariant;
}>`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  padding: 25px;
  overflow: auto;
  display: flex;

  flex-direction: column;
  gap: 20px;

  ${(props) => {
    if (props.variant === "large") {
      return css`
        width: 700px;
      `;
    }

    if (props.variant === "small") {
      return css`
        width: 670px;
      `;
    }
  }}
  max-height: 89vh;
  justify-content: space-between;
`;

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000064;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;

  justify-content: center;
`;

export const DialogContent = ({
  children,
  variant,
}: {
  children: React.ReactNode;
  variant: DialogContentVariant;
}) => {
  const [ref, bounds] = useMeasure();
  return (
    <StyledDialogContent
      variant={variant}
      ref={ref}
      style={{ minHeight: bounds.height }}
    >
      {children}
    </StyledDialogContent>
  );
};
