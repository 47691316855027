import styled, { css } from "styled-components";
import { Asterisk } from "../components-ui/Asterisk";
import { ConditionTag, LabelTagHelp } from "../components-ui/LabelTag";
import type { TextElement } from "../types/formTypes";
export function InputElement({ element }: { element: TextElement }) {
  return (
    <div>
      <Label>
        <Asterisk isDisplayed={!element.isOptional}></Asterisk>
        {element.label}
        {element.helplabel !== "" && <LabelTagHelp></LabelTagHelp>}
        {element.condition !== undefined && <ConditionTag></ConditionTag>}
      </Label>
      <FakeInput isLarge={element.isLongText}></FakeInput>
    </div>
  );
}

const FakeInput = styled.div<{ isLarge?: boolean }>`
  height: 40px;
  background: white;
  border-radius: 0.5rem;
  border: 2px solid #e2e6e9;
  max-width: 500px;

  ${(props) => {
    if (props.isLarge) {
      return css`
        height: 90px;
      `;
    }
  }}
`;

const Label = styled.div`
  margin-bottom: 5px;
`;
