import { Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

import {
  BsCalendarDate,
  BsCurrencyDollar,
  BsFileFont,
  BsListCheck,
} from "react-icons/bs";
import { CiCircleList } from "react-icons/ci";
import { RxHeading } from "react-icons/rx";
import { TbLetterCase } from "react-icons/tb";
type Props = {
  text: string;
  index: number;
  iconKey:
    | "text"
    | "currency"
    | "heading"
    | "date"
    | "listcheck"
    | "listradio"
    | "htmltext";
};
export function NewFormElement({ text, index, iconKey }: Props) {
  return (
    <Droppable droppableId={iconKey} isDropDisabled>
      {(provided, snapchot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <Draggable key={text} draggableId={text} index={index}>
            {(draggableProvided, snap) => {
              return (
                <>
                  <div
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    ref={draggableProvided.innerRef}
                  >
                    <NewFormElementContainer>
                      <IconContainer>
                        {iconKey === "currency" && (
                          <BsCurrencyDollar></BsCurrencyDollar>
                        )}
                        {iconKey === "text" && <TbLetterCase></TbLetterCase>}
                        {iconKey === "htmltext" && <BsFileFont></BsFileFont>}
                        {iconKey === "heading" && <RxHeading></RxHeading>}
                        {iconKey === "date" && (
                          <BsCalendarDate></BsCalendarDate>
                        )}
                        {iconKey === "listcheck" && <BsListCheck></BsListCheck>}
                        {iconKey === "listradio" && (
                          <CiCircleList></CiCircleList>
                        )}
                      </IconContainer>
                      <div style={{ textAlign: "center" }}>{text}</div>
                    </NewFormElementContainer>
                  </div>
                </>
              );
            }}
          </Draggable>
          {/**https://github.com/atlassian/react-beautiful-dnd/issues/518#issuecomment-705166960 */}
          <div style={{ maxHeight: "0px" }}>{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
}

const NewFormElementContainer = styled.div`
  background-color: white;
  padding: 10px;
  width: 106px;
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--color-main);
  color: var(--color-main);
  height: 120px;
  @media screen and (max-height: 850px) {
    height: 100px;
  }
`;

const IconContainer = styled.div`
  --icon-size: 50px;
  width: var(--icon-size);
  height: var(--icon-size);

  svg {
    color: var(--color-main);
    height: 100%;
    width: 100%;
  }
`;
