import styled from "styled-components";
import { ConditionTag, LabelTagHelp } from "../components-ui/LabelTag";
import { Stack } from "../components-ui/Stack";
import type { ListElement } from "../types/formTypes";

export function ListElementDisplay({ element }: { element: ListElement }) {
  return (
    <div>
      <Label>
        {element.label}{" "}
        {element.helplabel !== "" && <LabelTagHelp></LabelTagHelp>}
        {element.condition !== undefined && <ConditionTag></ConditionTag>}
      </Label>
      <Stack>
        {element.choices.slice(0, 3).map((c) => {
          return (
            <ChoiceContainer key={c.id}>
              <FakeRadio></FakeRadio>
              {c.label}
            </ChoiceContainer>
          );
        })}
        {element.choices.length > 3 && <ChoiceContainer>...</ChoiceContainer>}
      </Stack>
    </div>
  );
}

const Label = styled.div`
  margin-bottom: 5px;
`;

const ChoiceContainer = styled.div`
  background-color: var(--color-dark-background);
  padding: 10px;
  border-radius: 0.5rem;
  max-width: 500px;
`;

const FakeRadio = styled.div`
  display: inline-block;
  --fakecheckboxsize: 20px;
  vertical-align: -0.3rem;
  margin-right: 10px;
  border: 1px solid black;
  height: var(--fakecheckboxsize);
  width: var(--fakecheckboxsize);
  border-radius: 50%;
`;
