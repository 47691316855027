import { WritableDraft } from "immer/dist/internal";
import { useAtom } from "jotai";
import { formAtom } from "../../App";
import { InputGroup } from "../../components-ui/InputGroup";
import { IntegerInputGroup } from "../../components-ui/IntegerInputGroup";
import { Stack } from "../../components-ui/Stack";
import { SwitchGroup } from "../../components-ui/SwitchGroup";
import { NumberElement } from "../../types/formTypes";

export function FormNumberEditor({ element }: { element: NumberElement }) {
  const [, update] = useAtom(formAtom);

  const updateTextElement = (
    fn: (element: WritableDraft<NumberElement>) => void
  ) => {
    update((formelements) => {
      const foundElement = formelements.find((x) => x.id === element.id);
      if (!foundElement) return;
      if (foundElement.type === "number") {
        fn(foundElement);
      }
    });
  };

  return (
    <Stack>
      <InputGroup
        label={"Label (English)"}
        value={element.label}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.label = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Label (French)"}
        value={element.label2}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.label2 = val;
          });
        }}
      ></InputGroup>

      <SwitchGroup
        label="Amount is ($) currency"
        checked={element.currency !== undefined}
        onCheckedChange={(newVal) => {
          updateTextElement((prev) => {
            if (newVal) {
              prev.currency = {
                currencyAscii: "$",
                maxNumber: 1000000,
              };
            } else {
              delete prev.currency;
            }
          });
        }}
      ></SwitchGroup>
      {element.currency !== undefined && (
        <>
          <IntegerInputGroup
            label="Max Length"
            onChange={(val) => {
              updateTextElement((prev) => {
                if (prev.currency) {
                  prev.currency.maxNumber = val;
                }
              });
            }}
            value={element.currency.maxNumber}
          ></IntegerInputGroup>
        </>
      )}
      <SwitchGroup
        label="Required"
        checked={!element.isOptional}
        onCheckedChange={(newVal) => {
          updateTextElement((prev) => {
            prev.isOptional = !newVal;
          });
        }}
      ></SwitchGroup>
      <InputGroup
        label={"Optional Help Box Text (English)"}
        value={element.helplabel}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.helplabel = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Optional Help Box Text (French)"}
        value={element.helplabel2}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.helplabel2 = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Identifier"}
        value={element.safeId}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.safeId = val;
          });
        }}
      ></InputGroup>
    </Stack>
  );
}
