import * as Dialog from "@radix-ui/react-dialog";
import { WritableDraft } from "immer/dist/internal";
import React from "react";
import { Button } from "../../components-ui/Button";
import { IconButton } from "../../components-ui/IconButton";
import { InputGroup } from "../../components-ui/InputGroup";
import {
  DialogContent,
  DialogOverlay,
} from "../../components-ui/ModalElements";
import { Stack } from "../../components-ui/Stack";
import { ListElementChoice } from "../../types/formTypes";

type Props = {
  choice: ListElementChoice;
  updateChoice: (
    fn: (element: WritableDraft<ListElementChoice>) => void
  ) => void;
};

export function FormListElementEditor({ choice, updateChoice }: Props) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Dialog.Root
        open={open}
        onOpenChange={(o) => {
          setOpen(o);
        }}
      >
        <Dialog.Trigger asChild>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            buttonKey="pencil"
          ></IconButton>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Content
            asChild
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
          >
            <DialogOverlay>
              <DialogContent variant="small">
                <Stack>
                  <InputGroup
                    label={"Label (English)"}
                    value={choice.label}
                    onChange={(val) => {
                      updateChoice((prev) => {
                        prev.label = val;
                      });
                    }}
                  ></InputGroup>
                  <InputGroup
                    label={"Label (French)"}
                    value={choice.label2}
                    onChange={(val) => {
                      updateChoice((prev) => {
                        prev.label2 = val;
                      });
                    }}
                  ></InputGroup>
                  <InputGroup
                    label={"Identifier"}
                    value={choice.safeId}
                    onChange={(val) => {
                      updateChoice((prev) => {
                        choice.safeId = val;
                      });
                    }}
                  ></InputGroup>
                  <div>
                    <Dialog.Close asChild>
                      <Button variant="secondary">Close</Button>
                    </Dialog.Close>
                  </div>
                </Stack>
              </DialogContent>
            </DialogOverlay>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
