import { FormElement, isFormCheckBoxChild } from "../types/formTypes";
import { ZOldSchema } from "./ZodSchemas";

export function convertOld({
  old,
}: {
  old: unknown;
}):
  | { isValid: true; form: FormElement[]; title1: string; title2: string }
  | { isValid: false } {
  const parsedOldSchema = ZOldSchema.safeParse(old);

  if (parsedOldSchema.success) {
    const { fields, title, translations } = parsedOldSchema.data;

    function translate(k: string) {
      const result = {
        en: translations.en[k] ?? k,
        fr: translations.fr[k] ?? k,
      };

      return result;
    }

    function addFields(parent: string | null): FormElement[] {
      let innerResult: FormElement[] = [];
      const extractedFiekds = fields.filter((x) => x.parent === parent);

      for (const field of extractedFiekds) {
        let parsedCondition:
          | {
              id: string;
              valueId: string;
            }
          | undefined = undefined;

        const fieldConditions = field.conditions;
        if (fieldConditions) {
          parsedCondition = {
            id: fieldConditions.id,
            valueId: fieldConditions.value,
          };
        }
        if (field.type === "freeText") {
          innerResult.push({
            type: "freetext",
            id: field.id,
            condition: parsedCondition,
            html: translate(field.content).en,
            html2: translate(field.content).fr,
            safeId: field.id,
          });
        } else if (field.type === "object") {
          innerResult.push({
            type: "heading",
            level: 2,
            content: translate(field.title).en,
            content2: translate(field.title).fr,
            id: field.id,
            condition: parsedCondition,
          });

          innerResult = [...innerResult, ...addFields(field.id)];
        } else if (field.type === "string") {
          if (field.ui === "date") {
            innerResult.push({
              type: "date",
              helplabel: field.help ? translate(field.help).en : "",
              helplabel2: field.help ? translate(field.help).fr : "",
              id: field.id,
              isFutureDate:
                field.isFutureDate !== undefined ? field.isFutureDate : false,
              isPastDate:
                field.isPastDate !== undefined ? field.isPastDate : false,
              isOptional: field.optional ? field.optional : false,
              label: translate(field.title).en,
              label2: translate(field.title).fr,
              condition: parsedCondition,
              safeId: field.id,
            });
          } else if (field.ui === "currency") {
            innerResult.push({
              type: "number",
              label: translate(field.title).en,
              label2: translate(field.title).fr,
              helplabel: field.help ? translate(field.help).en : "",
              helplabel2: field.help ? translate(field.help).fr : "",
              id: field.id,
              safeId: field.id,
              isOptional: field.optional ? field.optional : false,
              condition: parsedCondition,
              currency: {
                currencyAscii: "$",
                maxNumber: 1000000000,
              },
            });
          } else {
            innerResult.push({
              type: "text",
              helplabel: field.help ? translate(field.help).en : "",
              helplabel2: field.help ? translate(field.help).fr : "",
              id: field.id,
              safeId: field.id,
              isLongText: field.ui === "textarea",
              isOptional: field.optional ? field.optional : false,
              label: translate(field.title).en,
              label2: translate(field.title).fr,
              maxLength: field.maxLength ? field.maxLength : 5000,
              condition: parsedCondition,
              mask: field.mask ? field.mask : undefined,
              doNotPrefillInCounterProposal:
                field.doNotPrefillInCounterProposal !== undefined
                  ? field.doNotPrefillInCounterProposal
                  : undefined,
            });
          }
        } else if (field.type === "list") {
          innerResult.push({
            displayAsRadio: true,
            helplabel: field.help ? translate(field.help).en : "",
            helplabel2: field.help ? translate(field.help).fr : "",
            type: "list",
            id: field.id,
            safeId: field.id,
            label: translate(field.title).en,
            label2: translate(field.title).fr,
            condition: parsedCondition,
            choices: field.elements.map((x) => {
              return {
                id: x,
                safeId: x,
                label: translate(x).en,
                label2: translate(x).fr,
              };
            }),
          });
        } else if (field.type === "checkbox") {
          innerResult.push({
            type: "checkbox",
            choices: field.elements.map((elem) => {
              return {
                children: addFields(elem.id).filter(isFormCheckBoxChild),
                id: elem.id,
                safeId: elem.id,
                label: translate(elem.title).en,
                label2: translate(elem.title).fr,
                helplabel: elem.help ? translate(elem.help).en : "",
                helplabel2: elem.help ? translate(elem.help).fr : "",
              };
            }),
            id: field.id,
            safeId: field.id,
            label: translate(field.title).en,
            label2: translate(field.title).fr,
            mustChooseAtLeastOne:
              field.optional !== undefined ? field.optional === false : true,
            condition: parsedCondition,
          });
        } else if (field.type === "floatNumber") {
          innerResult.push({
            type: "number",
            label: translate(field.title).en,
            label2: translate(field.title).fr,
            helplabel: field.help ? translate(field.help).en : "",
            helplabel2: field.help ? translate(field.help).fr : "",
            id: field.id,
            safeId: field.id,
            isOptional: field.optional ? field.optional : false,
            condition: parsedCondition,
            currency: field.currency
              ? {
                  currencyAscii: "$",
                  maxNumber: field.currency.maxNumber,
                }
              : undefined,
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const exhaustiveCheck: never = field;
        }
      }
      return innerResult;
    }

    const result: FormElement[] = addFields(null);
    return {
      isValid: true,
      form: result,
      title1: translate(title).en,
      title2: translate(title).fr,
    };
  } else {
    console.log(parsedOldSchema.error);
    console.log(old);
  }

  return { isValid: false };
}
