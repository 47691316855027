import * as Dialog from "@radix-ui/react-dialog";
import { WritableDraft } from "immer/dist/internal";
import { nanoid } from "nanoid";
import React from "react";
import styled from "styled-components";
import { Button } from "../../components-ui/Button";
import { IconButton } from "../../components-ui/IconButton";
import { InputGroup } from "../../components-ui/InputGroup";
import {
  DialogContent,
  DialogOverlay,
} from "../../components-ui/ModalElements";
import { Stack } from "../../components-ui/Stack";
import {
  FormCheckBoxChildren,
  FormCheckBoxChoice,
} from "../../types/formTypes";
import { FormChoiceQuestionEditor } from "./FormChoiceQuestionEditor";

type Props = {
  choice: FormCheckBoxChoice;
  updateChoice: (
    fn: (element: WritableDraft<FormCheckBoxChoice>) => void
  ) => void;
};

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export function FormCheckBoxChoiceEditor({ choice, updateChoice }: Props) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Dialog.Root
        open={open}
        onOpenChange={(o) => {
          setOpen(o);
        }}
      >
        <Dialog.Trigger asChild>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            buttonKey="pencil"
          ></IconButton>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Content
            asChild
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
          >
            <DialogOverlay>
              <DialogContent variant="small">
                <Stack>
                  <InputGroup
                    label={"Label (English)"}
                    value={choice.label}
                    onChange={(val) => {
                      updateChoice((prev) => {
                        prev.label = val;
                      });
                    }}
                  ></InputGroup>
                  <InputGroup
                    label={"Label (French)"}
                    value={choice.label2}
                    onChange={(val) => {
                      updateChoice((prev) => {
                        prev.label2 = val;
                      });
                    }}
                  ></InputGroup>

                  <InputGroup
                    label={"Optional Help Box Text (English)"}
                    value={choice.helplabel}
                    onChange={(val) => {
                      updateChoice((prev) => {
                        prev.helplabel = val;
                      });
                    }}
                  ></InputGroup>
                  <InputGroup
                    label={"Optional Help Box Text (French)"}
                    value={choice.helplabel2}
                    onChange={(val) => {
                      updateChoice((prev) => {
                        prev.helplabel2 = val;
                      });
                    }}
                  ></InputGroup>
                  {choice.children.length === 0 && (
                    <ButtonsContainer>
                      <Button
                        variant="main"
                        onClick={() => {
                          updateChoice((prev) => {
                            const id = nanoid();
                            prev.children.push({
                              type: "text",
                              label: "Label",
                              label2: "Label",
                              id: id,
                              safeId: id,
                              isLongText: false,
                              isOptional: false,
                              maxLength: 500,
                              helplabel: "",
                              helplabel2: "",
                            });
                          });
                        }}
                      >
                        Add Supplemental Text Question
                      </Button>
                      <Button
                        variant="main"
                        onClick={() => {
                          updateChoice((prev) => {
                            const id = nanoid();
                            prev.children.push({
                              type: "date",
                              label: "Label",
                              label2: "Label",
                              id: id,
                              safeId: id,
                              isFutureDate: false,
                              isPastDate: false,
                              isOptional: false,
                              helplabel: "",
                              helplabel2: "",
                            });
                          });
                        }}
                      >
                        Add Supplemental Date Question
                      </Button>
                      <Button
                        variant="main"
                        onClick={() => {
                          updateChoice((prev) => {
                            const id = nanoid();
                            prev.children.push({
                              type: "number",
                              label: "Label",
                              label2: "Label",
                              id: id,
                              safeId: id,
                              isOptional: false,
                              helplabel: "",
                              helplabel2: "",
                            });
                          });
                        }}
                      >
                        Add Supplemental Currency Question
                      </Button>
                    </ButtonsContainer>
                  )}

                  {choice.children.length > 0 && (
                    <div>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          updateChoice((prev) => {
                            prev.children = [];
                          });
                        }}
                      >
                        Delete Supplemental Question
                      </Button>
                    </div>
                  )}
                  <SupplementaryQuestionZone>
                    <Stack>
                      {choice.children.map((x) => {
                        const updateChoiceQuestion = (
                          fn: (
                            element: WritableDraft<FormCheckBoxChildren>
                          ) => void
                        ) => {
                          updateChoice((prev) => {
                            const foundQuestion = prev.children.find(
                              (c) => c.id === x.id
                            );
                            if (!foundQuestion) return;
                            fn(foundQuestion);
                          });
                        };

                        return (
                          <SupplementaryQuestion key={x.id}>
                            <FormChoiceQuestionEditor
                              editQuestion={updateChoiceQuestion}
                              question={x}
                            ></FormChoiceQuestionEditor>
                          </SupplementaryQuestion>
                        );
                      })}
                    </Stack>
                  </SupplementaryQuestionZone>
                  <div>
                    <Dialog.Close asChild>
                      <Button variant="secondary">Close</Button>
                    </Dialog.Close>
                  </div>
                </Stack>
              </DialogContent>
            </DialogOverlay>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}

const SupplementaryQuestionZone = styled.div``;

const SupplementaryQuestion = styled.div`
  background: #e2e6e9;
  padding: 10px;
  border-radius: 7px;
`;
