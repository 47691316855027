import React from "react";
import styled from "styled-components";
import { inputStyling } from "./shared";

const StyledInput = styled.input`
  ${inputStyling}
`;

const StyledLabel = styled.label`
  display: block;
`;

type Props = {
  onChange: (newVal: number) => void;
  value: number;
  label: string;
};

export const IntegerInputGroup = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    return (
      <div>
        <StyledLabel>{props.label}</StyledLabel>
        <StyledInput
          ref={ref}
          value={props.value === -1 ? "" : props.value.toString()}
          onChange={(e) => {
            const valueWithoutDigits = e.target.value.replace(/\D/g, "");
            if (valueWithoutDigits === "") {
              props.onChange(-1);
            } else {
              const val = Number.parseInt(valueWithoutDigits, 10);
              props.onChange(val);
            }
          }}
          onBlur={() => {
            if (props.value === -1) {
              props.onChange(0);
            }
          }}
        />
      </div>
    );
  }
);
