import { WritableDraft } from "immer/dist/internal";
import { useAtom } from "jotai";
import { formAtom } from "../../App";
import { InputGroup } from "../../components-ui/InputGroup";
import { IntegerInputGroup } from "../../components-ui/IntegerInputGroup";
import { Stack } from "../../components-ui/Stack";
import { SwitchGroup } from "../../components-ui/SwitchGroup";
import { TextElement } from "../../types/formTypes";

export function FormTextEditor({ element }: { element: TextElement }) {
  const [, update] = useAtom(formAtom);

  const updateTextElement = (
    fn: (element: WritableDraft<TextElement>) => void
  ) => {
    update((formelements) => {
      const foundElement = formelements.find((x) => x.id === element.id);
      if (!foundElement) return;
      if (foundElement.type === "text") {
        fn(foundElement);
      }
    });
  };

  return (
    <Stack>
      <InputGroup
        label={"Label (English)"}
        value={element.label}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.label = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Label (French)"}
        value={element.label2}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.label2 = val;
          });
        }}
      ></InputGroup>
      <IntegerInputGroup
        label="Max Length"
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.maxLength = val;
          });
        }}
        value={element.maxLength}
      ></IntegerInputGroup>
      <SwitchGroup
        label="Display as Long Text"
        checked={element.isLongText}
        onCheckedChange={(newVal) => {
          updateTextElement((prev) => {
            prev.isLongText = newVal;
          });
        }}
      ></SwitchGroup>
      <SwitchGroup
        label="Required"
        checked={!element.isOptional}
        onCheckedChange={(newVal) => {
          updateTextElement((prev) => {
            prev.isOptional = !newVal;
          });
        }}
      ></SwitchGroup>
      <InputGroup
        label={"Optional Help Box Text (English)"}
        value={element.helplabel}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.helplabel = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Optional Help Box Text (French)"}
        value={element.helplabel2}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.helplabel2 = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Mask"}
        value={element.mask ?? ""}
        onChange={(val) => {
          updateTextElement((prev) => {
            if (val === "") {
              prev.mask = undefined;
            } else {
              prev.mask = val;
            }
          });
        }}
      ></InputGroup>
      <SwitchGroup
        label="Do not prefill in Counter Proposal"
        checked={element.doNotPrefillInCounterProposal === true}
        onCheckedChange={(newVal) => {
          updateTextElement((prev) => {
            if (newVal) {
              prev.doNotPrefillInCounterProposal = true;
            } else {
              prev.doNotPrefillInCounterProposal = undefined;
            }
          });
        }}
      ></SwitchGroup>
      <InputGroup
        label={"Identifier"}
        value={element.safeId}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.safeId = val;
          });
        }}
      ></InputGroup>
    </Stack>
  );
}
