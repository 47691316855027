import styled, { css } from "styled-components";

type ButtonProps = {
  variant: "main" | "secondary";
};
export const Button = styled.button<ButtonProps>`
  min-width: 120px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  background-color: var(--color-main);
  color: white;
  min-height: 50px;
  cursor: pointer;

  &:hover {
    background-color: #00558c;
  }

  ${(props) => {
    if (props.variant === "secondary") {
      return css`
        background-color: white;
        border: 1px solid var(--color-main);
        color: var(--color-main);

        &:hover {
          background-color: #f8f9fb;
        }
      `;
    }
  }}
`;
