import { WritableDraft } from "immer/dist/internal";
import { useAtom } from "jotai";
import { formAtom } from "../../App";
import { InputGroup } from "../../components-ui/InputGroup";
import { Stack } from "../../components-ui/Stack";
import { HeadingElement } from "../../types/formTypes";

export function FormHeadingEditor({ element }: { element: HeadingElement }) {
  const [, update] = useAtom(formAtom);

  const updateTextElement = (
    fn: (element: WritableDraft<HeadingElement>) => void
  ) => {
    update((formelements) => {
      const foundElement = formelements.find((x) => x.id === element.id);
      if (!foundElement) return;
      if (foundElement.type === "heading") {
        fn(foundElement);
      }
    });
  };

  return (
    <Stack>
      <InputGroup
        label={"Label (English)"}
        value={element.content}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.content = val;
          });
        }}
      ></InputGroup>
      <InputGroup
        label={"Label (French)"}
        value={element.content2}
        onChange={(val) => {
          updateTextElement((prev) => {
            prev.content2 = val;
          });
        }}
      ></InputGroup>
    </Stack>
  );
}
