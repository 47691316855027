import styled from "styled-components";

export function LabelTag({ text }: { text: string }) {
  return (
    <>
      {" "}
      <TagStyled>{text}</TagStyled>
    </>
  );
}
export function ConditionTag() {
  return (
    <>
      {" "}
      <TagStyled>Conditional</TagStyled>
    </>
  );
}

const TagStyled = styled.div`
  display: inline-block;
  font-size: 0.8em;
  color: white;
  background-color: #a6a6a6;
  padding: 5px;
  border-radius: 8px;
  vertical-align: 1px;
  margin-left: 5px;
`;

export function LabelTagHelp() {
  return <LabelTag text="Help"></LabelTag>;
}
